import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner, Table, Container } from 'react-bootstrap';
import LoadingBar from 'react-top-loading-bar';
import './Home.css';

const API_URL_BEST = process.env.REACT_APP_API_URL_BEST;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL_COUNTS = process.env.REACT_APP_API_URL_COUNTS;

const Home = () => {
    const [featuredModels, setFeaturedModels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [counts, setCounts] = useState({ allCount: 0, commercialFreeCount: 0, cultsCUCount: 0 });
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const fetchFeaturedModels = async () => {
            setLoading(true);
            setProgress(30);
            try {
                const response = await axios.get(API_URL_BEST, {
                    headers: {
                        'x-api-key': API_KEY,
                    },
                });
                const data = JSON.parse(response.data.body);
                setFeaturedModels(data.items);
                setProgress(100);
            } catch (error) {
                console.error('Error fetching featured models:', error);
                setProgress(100);
            }
            setLoading(false);
        };

        const fetchCounts = async () => {
            try {
                const response = await axios.get(API_URL_COUNTS, {
                    headers: {
                        'x-api-key': API_KEY,
                    },
                });
                const data = response.data;
                setCounts(data);
            } catch (error) {
                console.error('Error fetching counts:', error);
            }
        };

        fetchFeaturedModels();
        fetchCounts();
    }, []);

    return (
        <Container fluid className="mt-4 main-content home-main-content">
            <LoadingBar
                color="#0052cc"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <div className="home-page-content">
                <div className="featured-models">
                    <h2 className="section-title">Featured Models</h2>
                    {loading ? (
                        <Spinner animation="border" variant="primary" />
                    ) : (
                        <Table striped bordered hover className="featured-table">
                            <thead>
                                <tr>
                                    <th>Model Name</th>
                                    <th>Designer</th>
                                    <th>Source</th>
                                    <th>License</th>
                                    <th>Featured Month</th>
                                </tr>
                            </thead>
                            <tbody>
                                {featuredModels.sort((a, b) => new Date(b.ScrapedAt) - new Date(a.ScrapedAt)).map((model, index) => (
                                    <tr key={index} className="clickable-row">
                                        <td colSpan="5">
                                            <a href={model.ModelURL} target="_blank" rel="noopener noreferrer" className="clickable-link">
                                                <span className="model-name">{model.ModelName}</span>
                                                <span className="author">{model.Author}</span>
                                                <span className="source">{model.Source}</span>
                                                <span className="license">{model.License}</span>
                                                <span className="scraped-at">{new Date(model.ScrapedAt).toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
                <div className="stats">
                    <h2 className="section-title">Stats</h2>
                    <p>Total Models: {counts.allCount}</p>
                    <p>Commercial Free Models: {counts.commercialFreeCount}</p>
                    <p>Cults CU Free Models: {counts.cultsCUCount}</p>
                </div>
            </div>
        </Container>
    );
};

export default Home;
