// src/Support.js
import React, { useState } from 'react';
import './Support.css';

const Support = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would handle form submission, e.g., sending the data to your email or a backend endpoint
        // For now, we'll just log the form data and show a success message
        console.log({ name, email, message });
        setSubmitted(true);
    };

    return (
        <div className="support-container">
            <h2 className="support-title">Support</h2>
            <p className="support-text">We are here to help! If you have any questions, please fill out the form below and we will get back to you as soon as possible.</p>
            <form className="support-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="submit-button">Send</button>
                {submitted && <p className="success-message">Thank you for your message! We will get back to you soon.</p>}
            </form>
        </div>
    );
};

export default Support;
