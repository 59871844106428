import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Spinner, Container } from 'react-bootstrap';
import LoadingBar from 'react-top-loading-bar';
import './Metrics.css';

const API_URL_METRICS = process.env.REACT_APP_API_URL_METRICS;

const Metrics = () => {
    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const calculateCosts = (metrics) => {
        const {
            apiInvocations,
            lambdaInvocations,
            lambdaCompute,
            dynamoDBReadRequests,
            dynamoDBWriteRequests,
            s3GetRequests,
            cloudFrontRequests,
        } = metrics.monthToDate; // Using month-to-date data for cost calculation

        // Costs calculation based on provided rates
        const apiGatewayCost = (apiInvocations / 1_000_000) * 3.50;
        const lambdaComputeCost = (Math.max(lambdaCompute - 400_000, 0) / 1_000_000) * 0.0000166667; // GB-seconds cost after free tier
        const lambdaRequestsCost = (Math.max(lambdaInvocations - 1_000_000, 0) / 1_000_000) * 0.20; // Requests cost after free tier
        const dynamoDBReadCost = (dynamoDBReadRequests / 1_000_000) * 0.25;
        const dynamoDBWriteCost = (dynamoDBWriteRequests / 1_000_000) * 1.25;
        const s3RequestsCost = (s3GetRequests / 10_000) * 0.004;
        const cloudFrontCost = 0; // Free tier

        const totalCost =
            apiGatewayCost +
            lambdaComputeCost +
            lambdaRequestsCost +
            dynamoDBReadCost +
            dynamoDBWriteCost +
            s3RequestsCost +
            cloudFrontCost;

        return {
            apiGatewayCost,
            lambdaComputeCost,
            lambdaRequestsCost,
            dynamoDBReadCost,
            dynamoDBWriteCost,
            s3RequestsCost,
            cloudFrontCost,
            totalCost,
        };
    };

    useEffect(() => {
        const fetchMetrics = async () => {
            setLoading(true);
            setProgress(30);
            try {
                const response = await axios.get(API_URL_METRICS, {
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                    },
                });
                const data = JSON.parse(response.data.body);
                console.log('Parsed Response Data:', data); // Log the parsed response data

                const lastCheckedMetrics = JSON.parse(localStorage.getItem('lastCheckedMetrics')) || {};

                const updatedMetrics = {
                    ...data,
                    sinceLastChecked: {
                        apiInvocations: data.apiInvocations - (lastCheckedMetrics.apiInvocations || 0),
                        lambdaInvocations: data.lambdaInvocations - (lastCheckedMetrics.lambdaInvocations || 0),
                        lambdaCompute: data.lambdaCompute - (lastCheckedMetrics.lambdaCompute || 0),
                        dynamoDBReadRequests: data.dynamoDBReadRequests - (lastCheckedMetrics.dynamoDBReadRequests || 0),
                        dynamoDBWriteRequests: data.dynamoDBWriteRequests - (lastCheckedMetrics.dynamoDBWriteRequests || 0),
                        s3GetRequests: data.s3GetRequests - (lastCheckedMetrics.s3GetRequests || 0),
                        cloudFrontRequests: data.cloudFrontRequests - (lastCheckedMetrics.cloudFrontRequests || 0),
                        certExpiryDays: data.certExpiryDays,
                    },
                };

                localStorage.setItem('lastCheckedMetrics', JSON.stringify(data));
                setMetrics(updatedMetrics);
                setProgress(100);
            } catch (error) {
                console.error('Error fetching metrics:', error);
                setProgress(100);
            }
            setLoading(false);
        };

        fetchMetrics();
    }, []);

    const costs = metrics ? calculateCosts(metrics) : null;

    return (
        <Container fluid className="mt-4 main-content metrics-main-content">
            <LoadingBar
                color="#0052cc"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <h2 className="metrics-title">Metrics</h2>
            {loading ? (
                <Spinner animation="border" variant="primary" />
            ) : (
                metrics && (
                    <div className="metrics-table-container">
                        <Table striped bordered hover className="metrics-table">
                            <thead>
                                <tr>
                                    <th>Metric</th>
                                    <th>Since Last Checked</th>
                                    <th>Past Hour</th>
                                    <th>Past 24 Hours</th>
                                    <th>Month to Date</th>
                                    <th>Total YTD</th>
                                    <th>Current Month Cost (USD)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>API Gateway Requests</td>
                                    <td>{metrics.sinceLastChecked.apiInvocations}</td>
                                    <td>{metrics.pastHour.apiInvocations}</td>
                                    <td>{metrics.past24Hours.apiInvocations}</td>
                                    <td>{metrics.monthToDate.apiInvocations}</td>
                                    <td>{metrics.ytd.apiInvocations}</td>
                                    <td>{costs ? costs.apiGatewayCost.toFixed(2) : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Lambda Invocations</td>
                                    <td>{metrics.sinceLastChecked.lambdaInvocations}</td>
                                    <td>{metrics.pastHour.lambdaInvocations}</td>
                                    <td>{metrics.past24Hours.lambdaInvocations}</td>
                                    <td>{metrics.monthToDate.lambdaInvocations}</td>
                                    <td>{metrics.ytd.lambdaInvocations}</td>
                                    <td>{costs ? costs.lambdaRequestsCost.toFixed(2) : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Lambda Compute (GB-seconds)</td>
                                    <td>{metrics.sinceLastChecked.lambdaCompute}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>{metrics.monthToDate.lambdaCompute}</td>
                                    <td>{metrics.ytd.lambdaCompute}</td>
                                    <td>{costs ? costs.lambdaComputeCost.toFixed(2) : '-'}</td>
                                </tr>
                                <tr>
                                    <td>DynamoDB Reads</td>
                                    <td>{metrics.sinceLastChecked.dynamoDBReadRequests}</td>
                                    <td>{metrics.pastHour.dynamoDBReadRequests}</td>
                                    <td>{metrics.past24Hours.dynamoDBReadRequests}</td>
                                    <td>{metrics.monthToDate.dynamoDBReadRequests}</td>
                                    <td>{metrics.ytd.dynamoDBReadRequests}</td>
                                    <td>{costs ? costs.dynamoDBReadCost.toFixed(2) : '-'}</td>
                                </tr>
                                <tr>
                                    <td>DynamoDB Writes</td>
                                    <td>{metrics.sinceLastChecked.dynamoDBWriteRequests}</td>
                                    <td>{metrics.pastHour.dynamoDBWriteRequests}</td>
                                    <td>{metrics.past24Hours.dynamoDBWriteRequests}</td>
                                    <td>{metrics.monthToDate.dynamoDBWriteRequests}</td>
                                    <td>{metrics.ytd.dynamoDBWriteRequests}</td>
                                    <td>{costs ? costs.dynamoDBWriteCost.toFixed(2) : '-'}</td>
                                </tr>
                                <tr>
                                    <td>S3 Get Requests</td>
                                    <td>{metrics.sinceLastChecked.s3GetRequests}</td>
                                    <td>{metrics.pastHour.s3GetRequests}</td>
                                    <td>{metrics.past24Hours.s3GetRequests}</td>
                                    <td>{metrics.monthToDate.s3GetRequests}</td>
                                    <td>{metrics.ytd.s3GetRequests}</td>
                                    <td>{costs ? costs.s3RequestsCost.toFixed(2) : '-'}</td>
                                </tr>
                                <tr>
                                    <td>CloudFront Requests</td>
                                    <td>{metrics.sinceLastChecked.cloudFrontRequests}</td>
                                    <td>{metrics.pastHour.cloudFrontRequests}</td>
                                    <td>{metrics.past24Hours.cloudFrontRequests}</td>
                                    <td>{metrics.monthToDate.cloudFrontRequests}</td>
                                    <td>{metrics.ytd.cloudFrontRequests}</td>
                                    <td>{costs ? costs.cloudFrontCost.toFixed(2) : '-'}</td>
                                </tr>
                                <tr>
                                    <td>ACM Cert Expiry (days)</td>
                                    <td>{metrics.certExpiryDays}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </Table>
                        <h3 className="total-cost-title">Total Cost (Current Month): {costs ? `$${costs.totalCost.toFixed(2)}` : '-'}</h3>
                    </div>
                )
            )}
        </Container>
    );
};

export default Metrics;
