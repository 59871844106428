import React from 'react';

const About = () => {
    return (
        <div className="container mt-4">
            <h1 className="text-center bebas-neue-regular">ABOUT STLDEX</h1>
            <p className="roboto-regular">
                Welcome to stldex, your ultimate resource for discovering and sharing 3D printable models. Our platform offers a vast collection of models,
                categorized and indexed for easy browsing. Whether you're looking for the latest designs, commercial-free models, or simply exploring new ideas,
                stldex is here to inspire and support your 3D printing journey.
            </p>
            <p className="roboto-regular">
                Our mission is to connect designers and makers, providing a space where creativity and innovation can thrive. Join our community, explore new designs,
                and share your own creations. We believe in the power of open source and the impact of shared knowledge. Together, we can push the boundaries of
                what's possible with 3D printing.
            </p>
            <p className="roboto-regular">
                Thank you for being a part of stldex. We look forward to seeing what you create!
            </p>
        </div>
    );
};

export default About;
