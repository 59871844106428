import React, { useState, useEffect } from 'react';
import { Table, Container } from 'react-bootstrap';
import LoadingBar from 'react-top-loading-bar';
import './RandomModels.css';

const RandomModels = ({ models, loading, requestSort, sortConfig }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (loading) {
            setProgress(30);
        } else {
            setProgress(100);
        }
    }, [loading]);

    return (
        <Container fluid className="mt-4 main-content random-models-main-content">
            <LoadingBar
                color="#0052cc"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <h2 className="metrics-title">Random Models</h2>
            <Table striped bordered hover className="random-models-table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('ModelName')}>Model Name</th>
                        <th onClick={() => requestSort('Author')}>Designer</th>
                        <th onClick={() => requestSort('Source')}>Source</th>
                        <th onClick={() => requestSort('License')}>License</th>
                        <th onClick={() => requestSort('Collection')}>Collection</th>
                        <th onClick={() => requestSort('ScrapedAt')}>Scraped At</th>
                    </tr>
                </thead>
                <tbody>
                    {models.map((model, index) => (
                        <tr key={index} className="clickable-row">
                            <td colSpan="6">
                                <a href={model.ModelURL} target="_blank" rel="noopener noreferrer" className="clickable-link">
                                    <span>{model.ModelName}</span>
                                    <span>{model.Author}</span>
                                    <span>{model.Source}</span>
                                    <span>{model.License}</span>
                                    <span>{model.Collection}</span>
                                    <span>{new Date(model.ScrapedAt).toLocaleString()}</span>
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default RandomModels;
