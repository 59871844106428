import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import Fuse from 'fuse.js';
import About from './About';
import Support from './Support';
import Home from './Home';
import AllModels from './AllModels';
import RandomModels from './RandomModels';
import Metrics from './Metrics';
import { Navbar, Nav, Container, Row, Col, Form } from 'react-bootstrap';
import './App.css';
import LoadingBar from 'react-top-loading-bar';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL_RANDOM = process.env.REACT_APP_API_URL_RANDOM;
const API_URL_COUNTS = process.env.REACT_APP_API_URL_COUNTS;
const SITE_NAME = process.env.REACT_APP_SITE_NAME;

const fuseOptions = {
    keys: ['ModelName', 'Author', 'License', 'Collection', 'Source', 'ScrapedAt'],
    threshold: 0.3
};

const App = () => {
    const [models, setModels] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState('ALL FREE');
    const [sortConfig, setSortConfig] = useState({ key: 'ScrapedAt', direction: 'descending' });
    const [loading, setLoading] = useState(false);
    const [counts, setCounts] = useState({ allCount: 0, commercialFreeCount: 0, cultsCUCount: 0 });
    const location = useLocation();
    const [progress, setProgress] = useState(0);

    const fetchModels = useCallback(async () => {
        setLoading(true);
        setProgress(30);
        try {
            const response = await axios.get(API_URL, {
                headers: {
                    'x-api-key': API_KEY
                }
            });
            const data = response.data;
            setModels(data.items);
            setSearchResults(data.items); // Initialize search results with all models
            setProgress(100);
        } catch (error) {
            console.error("Error fetching data:", error);
            setProgress(100);
        }
        setLoading(false);
    }, []);

    const fetchCounts = useCallback(async () => {
        try {
            const response = await axios.get(API_URL_COUNTS, {
                headers: {
                    'x-api-key': API_KEY
                }
            });
            const data = response.data;
            setCounts(data);
        } catch (error) {
            console.error("Error fetching counts:", error);
        }
    }, []);

    const fetchRandomModels = useCallback(async () => {
        setLoading(true);
        setProgress(30);
        try {
            const response = await axios.get(API_URL_RANDOM, {
                headers: {
                    'x-api-key': API_KEY
                }
            });
            const data = response.data;
            setModels(data.models);
            setSearchResults(data.models); // Initialize search results with random models
            setProgress(100);
        } catch (error) {
            console.error("Error fetching random data:", error);
            setProgress(100);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (location.pathname === '/metrics' || location.pathname === '/') {
            return; // Do not fetch counts when on /metrics or home
        }
        fetchCounts();
        if (location.pathname === '/random') {
            fetchRandomModels();
        } else if (location.pathname === '/all') {
            fetchModels();
        }
    }, [fetchModels, fetchRandomModels, fetchCounts, location.pathname]);

    const handleSearch = (e) => {
        const { value } = e.target;
        setQuery(value);
        filterAndSearchResults(value, filter);
    };

    const handleFilterChange = (e) => {
        const { value } = e.target;
        setFilter(value);
        filterAndSearchResults(query, value);
    };

    const filterAndSearchResults = (searchQuery, filterValue) => {
        let filteredModels = models;

        if (filterValue === 'COMMERCIAL FREE') {
            filteredModels = models.filter(model =>
                ['CC BY', 'CC BY-SA', 'CC0', 'CC BY-ND'].includes(model.License)
            );
        } else if (filterValue === 'CULTS CU FREE') {
            filteredModels = models.filter(model => model.License === 'CULTS CU');
        }

        if (searchQuery) {
            const fuse = new Fuse(filteredModels, fuseOptions);
            const results = fuse.search(searchQuery).map(result => result.item);
            setSearchResults(results);
        } else {
            setSearchResults(filteredModels);
        }
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedModels = useMemo(() => {
        const sortableModels = [...searchResults];
        sortableModels.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        return sortableModels;
    }, [searchResults, sortConfig]);

    return (
        <Container fluid className="mt-4 bebas-neue-regular">
            <LoadingBar
                color="#0052cc"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <Navbar bg="dark" variant="dark" expand="lg" fixed="top" className="w-100">
                <Container fluid className="d-flex justify-content-between align-items-center">
                    <Navbar.Brand as={Link} to="/" className="navbar-brand">
                        <h3 className="bebas-neue-regular mb-0"><strong>{SITE_NAME}</strong></h3>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="align-items-center">
                            <Nav.Link as={Link} to="/all">ALL</Nav.Link>
                            <Nav.Link as={Link} to="/random">RANDOM</Nav.Link>
                            <Nav.Link as={Link} to="/metrics">METRICS</Nav.Link>
                            <Nav.Link as={Link} to="/support">SUPPORT</Nav.Link>
                            <Nav.Link as={Link} to="/about">ABOUT</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {(location.pathname === '/all' || location.pathname === '/random') && (
                <Container fluid className="search-filter-container">
                    <Row className="my-2 justify-content-center">
                        <Col xs={12} md={10} lg={8}>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search..."
                                        value={query}
                                        onChange={handleSearch}
                                        className="mb-2"
                                        style={{ borderColor: 'grey' }}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Select
                                        value={filter}
                                        onChange={handleFilterChange}
                                        className="slim-dropdown mb-2"
                                        style={{ borderColor: 'grey' }}
                                    >
                                        <option value="ALL FREE">ALL FREE ({counts.allCount})</option>
                                        <option value="COMMERCIAL FREE">COMMERCIAL FREE ({counts.commercialFreeCount})</option>
                                        <option value="CULTS CU FREE">CULTS CU FREE ({counts.cultsCUCount})</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            )}
            <Container fluid className={`mt-4 main-content ${location.pathname === '/' ? 'home-main-content' : ''}`}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/all" element={<AllModels models={sortedModels} loading={loading} requestSort={requestSort} sortConfig={sortConfig} />} />
                    <Route path="/random" element={<RandomModels models={sortedModels} loading={loading} requestSort={requestSort} sortConfig={sortConfig} />} />
                    <Route path="/metrics" element={<Metrics />} />
                </Routes>
            </Container>
        </Container>
    );
};

export default App;
